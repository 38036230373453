<template>
	<v-main v-if="loaded" fill-height class="mb-12">
		<v-container class="mb-12 pb-12">
		<v-layout flex align-center justify-center>
			<v-progress-circular indeterminate :size="150" :widht="95" color="secondary" />
		</v-layout>

		</v-container>
	</v-main>
</template>

<script>
	export default {
		name: 'MainLoaded',
		props: {
			loaded: Boolean
		}
	}
</script>

<style lang="scss" scoped>

</style>